import { Breadcrumb as AntBreadcrumb, Typography } from 'antd';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/lib/breadcrumb/Breadcrumb';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ElementIds } from '../../../constants/elementIds';

const Container = styled.div`
  min-height: 20px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const Breadcrumb = styled(AntBreadcrumb)`
  font-size: 13px;
`;

export const StyledMLink = styled(Link)`
  color: inherit;
  outline: 0;
  background: transparent !important;
`;

const Label = styled(Typography.Text)<{ isClickable?: boolean; isLast?: boolean }>`
  cursor: pointer;
  display: inline-block;
  max-width: 140px !important;
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  font-size: 13px;
  color: ${({ theme, isLast = false }) => (isLast ? theme.colorTextBase : theme.colorTextDisabled)};
  ${(props) =>
    !props.isClickable
      ? `
        cursor: initial;
      `
      : `
      &:hover {
    text-decoration: underline;
  }
  `};
`;

interface BreadcrumbsContainerProps {
  items: Partial<BreadcrumbItemType & BreadcrumbSeparatorType & { url?: string }>[];
}

const BreadcrumbsContainer: React.FC<BreadcrumbsContainerProps> = ({ items }) => {
  const breadcrumbItems = items.map((item, idx) => {
    const titleContent =
      typeof item.title === 'string' ? (
        <Label isLast={items.length - 1 === idx} isClickable={!!item.onClick || !!item.url}>
          {item.title}
        </Label>
      ) : (
        item.title
      );
    const title = !!item.url ? <StyledMLink to={item.url}>{titleContent}</StyledMLink> : titleContent;

    return {
      ...item,
      title
    };
  });

  return (
    <Container id={ElementIds.BREADCRUMBS}>
      <Breadcrumb items={breadcrumbItems} />
    </Container>
  );
};

export default BreadcrumbsContainer;
