import React from 'react';
import { useArtifactVersion } from '../hooks/useArtifactVersion';
import styled from 'styled-components';
import { Button, Collapse, Divider, Dropdown, List, Skeleton, TableColumnsType, Typography } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { ArtifactTarget, ResourceError } from '../../../api';
import { DataType } from './AllArtifactResources';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';
import Table from '../../../contexts/shared/components/Table/Table';

interface ArtifactDetailsProps {
  artifact: DataType;
  onSetErrorModal: (error: ResourceError) => void;
}

const Container = styled.div`
  padding: 10px 18px;
`;

const TextSuccess = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colorSuccess};
`;

const TextError = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colorError};
`;

const TextWarning = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.colorWarning};
`;

const ParagraphError = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.colorError};
`;

const DropdownItem = styled.li`
  color: ${({ theme }) => theme.colorPrimary};
  padding: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ArtifactDetails: React.FC<ArtifactDetailsProps> = (props) => {
  const { artifact, onSetErrorModal } = props;
  const artifactStatus = useArtifactVersion(artifact.artifactId, artifact.versionId, artifact.type);
  const projectInfrastructureStatus: string = artifactStatus.data?.infrastructureState.status ?? '';
  const labelByStatus: Record<string, any> = {
    SYNCED: <TextSuccess>SYNCED</TextSuccess>,
    OUT_OF_SYNC: <TextError>OUT OF SYNC</TextError>,
    DELETED: <TextError>DELETED</TextError>,
    SYNCING_IN_PROGRESS: <TextWarning>SYNCING IN PROGRESS</TextWarning>,
    SYNCING_PENDING: <TextWarning>SYNCING PENDING</TextWarning>
  };
  const statusLabel = labelByStatus?.[projectInfrastructureStatus] ?? 'UNKNOWN';
  const targetTableColumns: TableColumnsType<ArtifactTarget> = [
    {
      title: 'ID',
      key: 'id',
      render: (record) => String(record.id),
      width: '100px'
    },
    {
      title: 'Download Link',
      key: 'id',
      render: (record) => String(record.downloadLink),
      width: '500px'
    },
    {
      title: 'Managed',
      key: 'managed',
      render: (record) => String(record.managed)
    },
    {
      title: 'Checksum',
      key: 'sha256Checksum',
      render: (record: ArtifactTarget) => record.sha256Checksum || '-'
    }
  ];

  if (artifactStatus.data == null) {
    return (
      <Container>
        <Skeleton loading active>
          <List.Item.Meta />
        </Skeleton>
        <Skeleton loading active>
          <List.Item.Meta />
        </Skeleton>
      </Container>
    );
  }

  const items = (artifactStatus.data?.infrastructureState.resources || []).map((service, index: number) => ({
    key: String(index),
    label: (
      <span>
        {service.name} {labelByStatus[service.status]}
      </span>
    ),
    children: (
      <Container>
        <Typography.Title direction="rtl" level={5}>
          Service status: {labelByStatus[service.status]}
        </Typography.Title>
        <Divider orientation="left">Resource groups</Divider>
        <Collapse
          size="large"
          expandIconPosition="start"
          items={(service.resourceGroups || []).map((resourceGroup, resourceGroupIndex: number) => ({
            key: String(resourceGroupIndex),
            label: (
              <span>
                {resourceGroup.name} {labelByStatus[resourceGroup.status]}
              </span>
            ),
            children: (
              <Container>
                {resourceGroup.error != null ? (
                  <FlexDiv>
                    <ParagraphError onClick={() => onSetErrorModal(resourceGroup?.error!)}>
                      Error: {resourceGroup.error?.summary}. <a>More Info</a>
                    </ParagraphError>
                    <Typography.Title level={5}>Resource group status: {labelByStatus[resourceGroup.status]}</Typography.Title>
                  </FlexDiv>
                ) : (
                  <Typography.Title direction="rtl" level={5}>
                    Resource group status: {labelByStatus[resourceGroup.status]}
                  </Typography.Title>
                )}
                <Divider orientation="left">Additional info</Divider>
                <Container>
                  <Typography.Paragraph>ID: {resourceGroup.id}</Typography.Paragraph>
                </Container>
                <Divider orientation="left">Resources</Divider>
                <Collapse
                  size="large"
                  expandIconPosition="start"
                  items={(resourceGroup?.resources || []).map((resource, resourceIndex: number) => ({
                    key: String(resourceIndex),
                    label: (
                      <span>
                        {resource.name} {labelByStatus[resource.status]}
                      </span>
                    ),
                    children: (
                      <Container>
                        {resource.error != null ? (
                          <FlexDiv>
                            <ParagraphError onClick={() => onSetErrorModal(resource?.error!)}>
                              Error: {resource.error?.summary}. <a>More Info</a>
                            </ParagraphError>
                            <Typography.Title level={5}>Resource status: {labelByStatus[resource.status]}</Typography.Title>
                          </FlexDiv>
                        ) : (
                          <Typography.Title direction="rtl" level={5}>
                            Resource status: {labelByStatus[resource.status] || resource.status || 'UNKNOWN'}
                          </Typography.Title>
                        )}
                        <Divider orientation="left">Additional info</Divider>
                        <Container>
                          <Typography.Paragraph>ID: {resource.id}</Typography.Paragraph>
                        </Container>
                      </Container>
                    ),
                    extra:
                      (resource?.actions?.length ?? 0) > 0 ? (
                        <Dropdown
                          menu={{
                            items: resourceGroup?.actions.map((action) => ({
                              key: action.id,
                              label: <DropdownItem>{action.name}</DropdownItem>
                            }))
                          }}
                          trigger={['click']}
                          placement="bottomRight"
                        >
                          <Button onClick={(e) => e.stopPropagation()}>
                            <SettingOutlined />
                          </Button>
                        </Dropdown>
                      ) : undefined
                  }))}
                />
              </Container>
            ),
            extra:
              resourceGroup?.actions.length > 0 ? (
                <Dropdown
                  menu={{
                    items: resourceGroup?.actions.map((action) => ({
                      key: action.id,
                      label: <DropdownItem>{action.name}</DropdownItem>
                    }))
                  }}
                  trigger={['click']}
                  placement="bottomRight"
                >
                  <Button onClick={(e) => e.stopPropagation()}>
                    <SettingOutlined />
                  </Button>
                </Dropdown>
              ) : undefined
          }))}
        />
      </Container>
    ),
    extra:
      service?.actions.length > 0 ? (
        <Dropdown
          menu={{
            items: service?.actions.map((action) => ({
              key: action.id,
              label: <DropdownItem>{action.name}</DropdownItem>
            }))
          }}
          trigger={['click']}
          placement="bottomRight"
        >
          <Button onClick={(e) => e.stopPropagation()}>
            <SettingOutlined />
          </Button>
        </Dropdown>
      ) : undefined
  }));

  return (
    <Container>
      <Typography.Title direction="rtl" level={5}>
        Project infrastructure status: {statusLabel}
      </Typography.Title>
      <Divider orientation="left">Additional info</Divider>
      <Container>
        <Typography.Paragraph>PF ID: {artifactStatus.data.id}</Typography.Paragraph>
        <Typography.Paragraph>Artifact ID: {artifactStatus.data.artifactId}</Typography.Paragraph>
        <Typography.Paragraph>Artifact name: {artifactStatus.data.artifactName}</Typography.Paragraph>
        <Typography.Paragraph>Artifact type: {artifactStatus.data.artifactType}</Typography.Paragraph>
        <Typography.Paragraph>Version ID: {artifactStatus.data.versionId}</Typography.Paragraph>
        <Typography.Paragraph>Version name: {artifactStatus.data.versionName}</Typography.Paragraph>
        <Typography.Paragraph>Managed State: {artifactStatus.data.managedState}</Typography.Paragraph>
        <Typography.Paragraph>Project ID: {artifactStatus.data.projectId || '-'}</Typography.Paragraph>
        <Typography.Paragraph>Project Name: {artifactStatus.data.projectName || '-'}</Typography.Paragraph>
        {artifactStatus.data.targets.length > 0 && (
          <>
            <Typography.Paragraph>Targets:</Typography.Paragraph>
            <Table columns={targetTableColumns} dataSource={artifactStatus.data.targets} />
          </>
        )}
        <Typography.Paragraph>Created: {formatDateTime(new Date(artifactStatus.data.creationTimestamp ?? null))}</Typography.Paragraph>
        <Typography.Paragraph>Updated: {formatDateTime(new Date(artifactStatus.data.lastUpdatedTimestamp ?? null))}</Typography.Paragraph>
      </Container>
      <Divider orientation="left">Services</Divider>
      <Collapse size="large" expandIconPosition="start" items={items} />
    </Container>
  );
};

export default ArtifactDetails;
