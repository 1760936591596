import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useCurrentUser } from '../../session';
import { SharedAxiosInstance } from '../../../api/shared/sharedAxiosInstance';
import { useIsDevVersion } from '../../../contexts/navigation/hooks/useIsDevVersion';

const authorization = 'Hh9r2M+VxrWnSYRNecew4H8WybJatYA12HdWjMM2';
export const useGrafanaUrl = (id: string, name: string) => {
  const isDev = useIsDevVersion();
  const user = useCurrentUser();
  return useQuery(
    ['grafanaUrl', id],
    (): Promise<string> => {
      return new Promise((resolve, reject) => {
        SharedAxiosInstance.instance()
          .get(`https://${isDev ? 'test-' : ''}grafana-helper-api.kpi.${isDev ? 'dev.' : ''}pacts.cloud/${isDev ? 'Test' : 'Prod'}`, {
            headers: { 'Content-Type': 'application/json', Authorization: authorization },
            params: {
              name: name,
              email: user.email
            }
          })
          .then((response) => {
            try {
              const orgId = parseInt(response.data, 10);
              resolve(`https://kpi.${isDev ? 'dev.' : ''}pacts.cloud/grafana?orgId=${orgId}`);
            } catch (error) {
              console.error(error);
              return reject(new Error('Invalid Grafana Org id'));
            }
          })
          .catch((error) => {
            const ax = error as AxiosError;
            return reject(ax);
          });
      });
    },
    {
      retry: 0
    }
  );
};
