import { Button, Tooltip } from 'antd';
import { Project, SoftwareApp, SoftwareAppVersion, SoftwareComponent, Tool, ToolVersion } from '../../../api';
import { useComponentVersion } from '../hooks/useComponentVersion';
import { DownloadOutlined } from '@ant-design/icons';
import { DownloadButton } from '../../shared/components/Download/components/DownloadButton';
import { usePermissions } from '../../session/hooks/usePermissions';
import { AnimatedLoadingIcon } from '../../shared/components/icons/AnimatedLoadingIcon';
import { isValidChecksum } from '../../shared/components/Download/hooks/useArtifactoryLinkPreFlightCheck';
import { SoftwareComponentOpenInToolmanagerButton } from './SoftwareComponentOpenInToolmanagerButton';
import { PacTSIcon } from '../../../contexts/shared/components/icons/PacTSIcon';

const mapSoftwareComponentToDownloadableApp = (component: SoftwareComponent): { component: SoftwareApp | Tool; version: SoftwareAppVersion | ToolVersion } => {
  // best-effort mapping of received component to app and version
  // reduces number of necessary backend calls
  // and makes component available in case of missing permissions
  if (component.type === 'tool') {
    const versionFromComponent: ToolVersion = {
      idToolVersion: component.versionId,
      releaseNotes: '',
      state: 'consistent',
      // this is a hack to force-allow downloading even if we have not artifactory
      // download link in deployment plans
      // this download link is actually ignored by the web ui
      downloadLink: 'https://artifactory.siemens-energy.com/artifactory/force-allow-download',
      sha256: (component.targets?.length ?? 0) > 0 ? component.targets![0].sha256 : undefined,
      version: component.version
    };
    const appFromComponent: Tool = {
      name: component.name,
      category: component.categories?.map((c) => ({ name: c.name, id: c.id, description: '' })) ?? [],
      description: '',
      documentationLink: '',
      id: component.id,
      installationOptions: '',
      latestVersion: versionFromComponent,
      deprecated: false
    };

    return { component: appFromComponent, version: versionFromComponent };
  }

  const versionFromComponent: SoftwareAppVersion = {
    idSoftwareAppVersion: component.versionId,
    releaseNotes: '',
    state: 'consistent',
    targets: (component.targets ?? []).map((t) => ({
      // this is a hack to force-allow downloading even if we have not artifactory
      // download link in deployment plans
      // this download link is actually ignored by the web ui
      downloadLink: 'https://artifactory.siemens-energy.com/artifactory/force-allow-download',
      target: { idTarget: t.id, name: t.name },
      sha256: t.sha256
    })),
    version: component.version
  };
  const appFromComponent: SoftwareApp = {
    name: component.name,
    categories: [],
    description: '',
    documentationLink: '',
    idSoftwareApp: component.id,
    installationOptions: '',
    latestVersion: versionFromComponent
  };

  return { component: appFromComponent, version: versionFromComponent };
};

export const SoftwareComponentOpenGateway = (props: { project: Project; component: SoftwareComponent; mode: 'download' | 'toolmanager' }) => {
  // We need to make sure that the snapshot targets (at least one) that we're using have checksum, otherwise we should use the ones from engineering service
  const isFullComponent = (props.component.targets?.length ?? 0) > 0 && props.component.targets?.some((target) => isValidChecksum(target?.sha256));

  const { query: queriedVersion, hasPermission: hasQueryPermission } = useComponentVersion(props.project.idProject, props.component, !isFullComponent);

  const permissions = usePermissions({
    projectId: props.project.idProject.toString(),
    toolId: props.component.id.toString(),
    softwareAppId: props.component.id.toString(),
    idToolVersion: props.component.versionId.toString(),
    idSoftwareAppVersion: props.component.versionId.toString()
  });

  const isProjectApp = props.component.scope === 'project' && props.component.type === 'app';
  const isCommonApp = props.component.scope === 'common' && props.component.type === 'app';
  const isCommonTool = props.component.scope === 'common' && props.component.type === 'tool';

  const hasPermissions =
    (isProjectApp &&
      permissions.engineeringSvc$getProjectSoftwareAppVersionTargetDownload &&
      permissions.engineeringSvc$headProjectSoftwareAppVersionTargetDownload) ||
    (isCommonApp &&
      permissions.engineeringSvc$getCommonSoftwareAppVersionTargetDownload &&
      permissions.engineeringSvc$headCommonSoftwareAppVersionTargetDownload) ||
    (isCommonTool && permissions.engineeringSvc$getToolVersionDownload && permissions.engineeringSvc$headToolVersionDownload);

  const hasData = (!isFullComponent && queriedVersion.data && queriedVersion.isSuccess) || isFullComponent;
  const canLoadData = hasQueryPermission && !isFullComponent;
  const { component: mappedComponent, version: mappedVersion } = mapSoftwareComponentToDownloadableApp(props.component);

  const usedComponent = isFullComponent ? mappedComponent : queriedVersion.data?.component;
  const usedComponentVersion = isFullComponent ? mappedVersion : queriedVersion.data?.version;

  let button: React.ReactNode;

  // if the user does not have download permissions, hide component
  if (!hasPermissions) {
    return null;
  }

  // if the user does not have access to ImPacTS, hide component
  if (props.mode === 'toolmanager' && !permissions.impacts$use) {
    return null;
  }

  if (!hasData && canLoadData) {
    button = <Button icon={<AnimatedLoadingIcon />} type="text" disabled />;
  } else if (hasData && !!usedComponent && !!usedComponentVersion) {
    if (props.mode === 'download') {
      button = (
        <Tooltip title="Download">
          <DownloadButton
            icon={<DownloadOutlined />}
            artifact={usedComponent}
            version={usedComponentVersion}
            projectId={isProjectApp ? props.project.idProject : undefined}
            type="text"
          />
        </Tooltip>
      );
    } else if (props.mode === 'toolmanager') {
      button = <SoftwareComponentOpenInToolmanagerButton project={props.project} component={props.component} />;
    }
  } else {
    button = <Button icon={props.mode === 'download' ? <DownloadOutlined /> : <PacTSIcon />} disabled type="text" />;
  }

  return button;
};
