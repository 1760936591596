import { useEffect } from 'react';
import { Project } from '../../../api/engineering/domain/types';
import { useSearchParameter } from '../../../contexts/navigation/hooks/useSearchParameter';

export function useProjectTypeParam(requestedProject: Project | undefined) {
  const [typeParam, setSearchTypeValue] = useSearchParameter('type', undefined, false, true);

  useEffect(() => {
    if (!requestedProject) {
      return;
    }

    const projectTypeId = requestedProject?.projectType.idProjectType ?? 1;
    setSearchTypeValue(String(projectTypeId));
  }, [requestedProject, setSearchTypeValue]);

  return typeParam;
}
