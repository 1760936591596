import { useState, useEffect } from 'react';
import { ElementIds } from '../../../constants/elementIds';

export const useProjectsMenuWidth = () => {
  // initially assume a width of 250px
  const [menuWidth, setMenuWidth] = useState(250);

  // read and set the actual height of header on initial render
  useEffect(() => {
    setMenuWidth(Math.ceil(document.getElementById(ElementIds.PROJECTS_MENU)?.clientWidth ?? 0));
  }, [setMenuWidth]);

  return menuWidth;
};
