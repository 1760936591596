import { useState, useEffect } from "react";
import { ElementIds } from "../../../constants/elementIds";

export const useAppHeaderHeight = ()=>{
  // initially assume a height of 65px
  const [headerHeight, setHeaderHeight] = useState(65);

  // read and set the actual height of header on initial render
  useEffect(() => {
    setHeaderHeight(Math.ceil(document.getElementById(ElementIds.APP_HEADER)?.clientHeight ?? 0));
  }, [setHeaderHeight]);

  return headerHeight;
}