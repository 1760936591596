import { Button } from 'antd';
import { useState, useCallback } from 'react';
import { useMutationObservable } from '../hooks/useMutationObservable';
import { MarkdownRenderer } from './MarkdownRenderer';
import { ScrollDialog } from '../../layout/components/ScrollDialog';

export const MarkdownPreview = (props: { content: string; title: string; style?: React.CSSProperties; maxHeight?: number }) => {
  const [visible, setVisible] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [markdownRef, setMarkdownRef] = useState<HTMLDivElement | null>(null);

  const calculateOverflow = useCallback(() => {
    setIsOverflowing(false);

    if (markdownRef && (markdownRef.clientHeight < markdownRef.scrollHeight || markdownRef.clientWidth < markdownRef.scrollWidth)) {
      setIsOverflowing(true);
    }
  }, [markdownRef, setIsOverflowing]);

  useMutationObservable(markdownRef, calculateOverflow);

  const onPressSeeMore = () => setVisible(true);

  return (
    <>
      <div ref={(ref) => setMarkdownRef(ref)} style={{ ...props.style, maxHeight: props.maxHeight ?? 150, overflow: 'hidden' }}>
        <MarkdownRenderer>{props.content}</MarkdownRenderer>
      </div>
      {isOverflowing && (
        <Button type="link" style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: -15 }} onClick={() => onPressSeeMore()}>
          See more
        </Button>
      )}
      <ScrollDialog
        title={props.title}
        centered
        open={visible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        okText="Close"
      >
        <MarkdownRenderer>{props.content}</MarkdownRenderer>
      </ScrollDialog>
    </>
  );
};
