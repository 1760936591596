import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BundleRelease, SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { presentSuccess } from '../../../api/shared/successPresenter';

export function useUnstuckSyncingCommonSoftwareAppVersion() {
  const { backend } = useEngineeringBackend();
  const queryClient = useQueryClient();

  return useMutation<SoftwareAppVersion, any, [string, SoftwareAppVersion], SoftwareAppVersion[]>(async ([softwareAppId, softwareAppVersion]) => {
    const res = await backend.patchCommonAppVersion(softwareAppId, {
      idSoftwareAppVersion: softwareAppVersion.idSoftwareAppVersion,
      targets: softwareAppVersion.targets.map((t) => ({
        target: { idTarget: t.target.idTarget },
        downloadLink: t.downloadLink.startsWith('tmp://') ? 'invalid' : t.downloadLink
      }))
    });

    queryClient.invalidateQueries(['commonSoftwareappVersions', softwareAppId]);
    queryClient.invalidateQueries(['commonSoftwareapp', softwareAppId]);
    queryClient.invalidateQueries(['componentVersion', 'app', 'common', softwareAppId.toString(), softwareAppVersion.idSoftwareAppVersion.toString()]);

    // hard reset to force update
    queryClient.resetQueries(['commonSoftwareAppVersionVulnerabilities', softwareAppId.toString(), softwareAppVersion.idSoftwareAppVersion.toString()]);

    // Hack to make sure the Common Apps list is updated and rerendered
    // Required since latest versions of tool might be affected
    queryClient.invalidateQueries(['commonSoftwareapps']);

    // Invalidate bundle config versions also since they contain the sw app releases
    const queries = queryClient.getQueriesData<BundleRelease>(['bundleConfigurationVersion']) ?? [];
    const toInvalidate = queries.filter((q) => q[1]?.softwareApps?.some((a) => a.idSoftwareApp.toString() === softwareAppId));
    toInvalidate.forEach((q) => queryClient.invalidateQueries(q[0]));

    presentSuccess(`Successfully unstuck Version ${softwareAppVersion.version}`);
    return res;
  });
}
