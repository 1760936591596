import { Skeleton, Space } from 'antd';
import { usePermissions } from '../../session/hooks/usePermissions';
import { UpdateEngineeringToolVersion } from './UpdateEngineeringToolVersion';
import { EngineeringToolsOpenInToolManagerButton } from './EngineeringToolsOpenInToolManagerButton';
import { SemanticVersion } from '../../../domain/versioning/semanticVersion';
import Table from '../../../contexts/shared/components/Table/Table';

import { useDeleteEngineeringToolVersion, useEngineeringTool, useEngineeringToolVersions } from '../hooks';

import { ComponentVersionListEntry } from '../../../contexts/shared/components/ComponentVersionListEntry';
import { DownloadContextMenuButton } from '../../shared/components/Download/components/DownloadContextMenuButton';
import { EngineeringToolVersionVulnerabilityButton } from './EngineeringToolVersionVulnerabilityButton';

import type { Project, Tool, ToolVersion } from '../../../api/engineering/domain/types';
import { useCallback, useMemo } from 'react';

import { ExpandableMenu } from '../../../contexts/shared/components/ExpandableMenu';
import { LatestTag } from '../../../contexts/shared/components/LatestTag';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';
import { ConfirmationButton } from '../../../contexts/shared/components/ConfirmationButton';
import { UnstuckEngineeringToolVersion } from './UnstuckSyncingEngineeringToolVersion';

export type EngineeringToolVersionsListProps = {
  engineeringTool: Tool;
  project?: Project;
};

export const EngineeringToolVersionsList = (props: EngineeringToolVersionsListProps) => {
  const versions = useEngineeringToolVersions(props.engineeringTool.id.toString());
  const versionSearch = useTableSearch({ searchValueProvider: 'version', searchParamId: 'version' });
  const releaseNotesSearch = useTableSearch({
    searchValueProvider: (v: ToolVersion) => {
      return [v.version, v.releaseNotes, formatDateTime(new Date(v.createdAt || 0)), v.createdBy, formatDateTime(new Date(v.updatedAt || 0)), v.updatedBy]
        .filter(Boolean)
        .join(' ');
    },
    searchParamId: 'releaseNotes'
  });
  const tool = useEngineeringTool(props.engineeringTool.id?.toString() || '');
  const deleteEngineeringToolVersion = useDeleteEngineeringToolVersion();
  const permissions = usePermissions({ toolId: props.engineeringTool.id.toString(), projectId: props.project?.idProject.toString() });

  const latestVersion = props.engineeringTool?.latestVersion?.idToolVersion;
  const versionSorter = useCallback(
    (a: ToolVersion, b: ToolVersion) => {
      if (a.idToolVersion === latestVersion) return -1;
      if (b.idToolVersion === latestVersion) return 1;
      return SemanticVersion.sorter(a.version, b.version);
    },
    [latestVersion]
  );

  const data = useMemo(() => {
    return (versions.data ?? []).sort(versionSorter);
  }, [versions.data, versionSorter]);

  const columns = [
    {
      title: 'Version',
      key: 'version',
      ...versionSearch,
      width: 160,
      render: (record: ToolVersion) => {
        return (
          <>
            <Space wrap={true} size={[8, 4]}>
              {record.idToolVersion === tool.data?.latestVersion?.idToolVersion && <LatestTag />}
              {record.version}
              <EngineeringToolVersionVulnerabilityButton tool={props.engineeringTool} version={record} />
            </Space>
          </>
        );
      }
    },
    {
      title: 'Release Notes',
      key: 'releaseNotes',
      ...releaseNotesSearch,
      showSorterTooltip: { title: 'Sort by creation date' },
      sorter: {
        compare: (a: ToolVersion, b: ToolVersion): number => {
          return new Date(a.createdAt ?? 0).getTime() - new Date(b.createdAt ?? 0).getTime();
        }
      },
      render: (record: ToolVersion) => <ComponentVersionListEntry record={record} showAudit={permissions.webui$showComponentDetails} />
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (record: ToolVersion) => {
        return (
          <ExpandableMenu textType id={`tool-version-actions-${props.engineeringTool.id}-${record.idToolVersion}`}>
            <DownloadContextMenuButton artifact={props.engineeringTool} version={record} />
            <EngineeringToolsOpenInToolManagerButton selection={{ engineeringTool: props.engineeringTool, version: record }} project={props.project} />
            <UpdateEngineeringToolVersion tool={props.engineeringTool} toolVersion={record} />
            <UnstuckEngineeringToolVersion tool={props.engineeringTool} toolVersion={record} />
            {permissions.engineeringSvc$deleteToolVersion && (
              <ConfirmationButton
                danger
                caption="Delete"
                disabled={record.idToolVersion === tool.data?.latestVersion?.idToolVersion}
                onOk={() => {
                  deleteEngineeringToolVersion.mutate([props.engineeringTool, record]);
                }}
              />
            )}
          </ExpandableMenu>
        );
      },
      width: 100
    }
  ];

  return (
    <Skeleton loading={versions.isLoading}>
      <Table
        tableLayout="fixed"
        columns={columns}
        rowKey={(record: ToolVersion) => record.idToolVersion?.toString() || ''}
        dataSource={data}
        sticky={{
          offsetHeader: -25
        }}
      />
    </Skeleton>
  );
};
