import { useQuery } from '@tanstack/react-query';
import { useEngineeringBackend } from '../../../api/engineering/hooks/useEngineeringBackend';
import { Project } from '../../../api/engineering/domain/types';
import { usePermissions } from '../../session/hooks/usePermissions';

export function useProject(projectId: string) {
  const { backend } = useEngineeringBackend();
  const permissions = usePermissions({ projectId });
  const parsedProjectId = parseInt(projectId, 10);
  const requestedProject = useQuery<Project, [string, string]>(
    ['project', projectId],
    (): Promise<Project> => {
      return backend.queryProject(projectId);
    },
    {
      enabled: parsedProjectId > -1 && permissions.engineeringSvc$getProject
    }
  );

  return requestedProject;
}
