import { useQuery } from '@tanstack/react-query';
import { useDeploymentBackend } from '../../../api/deployment/hooks/useDeploymentBackend';
import { usePermissions } from '../../session';
import { Environment } from '../../../api';
import { Comparator } from '../../../domain';

export const useDeploymentEnvironments = (projectId: number, isEnabled = true) => {
  const { backend } = useDeploymentBackend();
  const permissions = usePermissions({ projectId: projectId.toString() });
  return useQuery(
    ['deploymentEnvironments', projectId],
    async (): Promise<Environment[]> => {
      const environments = await backend.getDeploymentEnvironments(projectId);
      return environments.sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name));
    },
    {
      enabled: isEnabled && permissions.deploymentSvc$getEnvironments
    }
  );
};
