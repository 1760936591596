import { uniq } from 'lodash';
import { useMemo, useState } from 'react';
import { Skeleton, Space, TableColumnType, Typography } from 'antd';
import Table from '../../../contexts/shared/components/Table/Table';
import { Project, ProjectResource, ResourceError } from '../../../api';
import styled from 'styled-components';
import { useAllProjectsResources } from '../hooks/useProjectsResources';
import { useDebounce } from '../../../contexts/shared/hooks/useDebounce';
import ProjectDetails from './ProjectDetails';
import { useProjects } from '../../../contexts/projects/hooks';
import { ImportProject } from './ImportProject';
import { useTableSearch } from '../../../contexts/shared/components/TableSearch';
import { useTableFilter } from '../../../contexts/shared/components/TableFilter';
import { StyledModal } from '../../../contexts/shared/components/StyledModal';
import { formatDateTime } from '../../../contexts/shared/components/formatDate';

export interface DataType {
  key: React.Key;
  name: string;
  status: string;
  creationTimestamp: Date;
  lastUpdatedTimestamp: Date;
}

export type ProjectFormationType = Omit<ProjectResource, 'creationTimestamp' | 'lastUpdatedTimestamp'> & {
  creationTimestamp: Date;
  lastUpdatedTimestamp: Date;
};

type RowData = {
  project?: Project;
  pf?: ProjectFormationType;
};

const FullWidthSpace = styled(Space)`
  width: 100%;
`;

export const AllProjectResources = () => {
  const projectResources = useAllProjectsResources();
  const projects = useProjects();
  const isLoading = useDebounce(projectResources.isLoading || projects.isLoading || projectResources.isFetchingNextPage, 10, true);

  const statuses: string[] = [...uniq(projectResources.data?.map((project) => project?.infrastructureStatus) || []), 'uninitialized'];
  const projectTypes: string[] = [...uniq(projectResources.data?.map((project) => project?.type) || []), 'undefined'];
  const solutionTypes: string[] = [...uniq(projectResources.data?.map((project) => project?.solutionType) || []), 'undefined'];
  const creationDateSorter = (artifactA: RowData, artifactB: RowData) =>
    (artifactA.pf?.creationTimestamp || new Date(0)).getTime() - (artifactB.pf?.creationTimestamp || new Date(0)).getTime();
  const updationDateSorter = (artifactA?: RowData, artifactB?: RowData) =>
    (artifactA?.pf?.lastUpdatedTimestamp ?? new Date(0)).getTime() - (artifactB?.pf?.lastUpdatedTimestamp || new Date(0)).getTime();
  const [errorModal, setErrorModal] = useState<null | ResourceError>(null);

  const merged = useMemo(() => {
    const ret: {
      [key: number]: { project?: Project; pf?: ProjectFormationType };
    } = {};

    projects.data?.forEach((p) => (ret[p.idProject] = { ...ret[p.idProject], project: p }));
    projectResources.data?.forEach(
      (pr) =>
        (ret[parseInt(pr.id, 10)] = {
          ...ret[parseInt(pr.id, 10)],
          pf: {
            ...pr,
            creationTimestamp: new Date(pr.creationTimestamp),
            lastUpdatedTimestamp: new Date(pr.lastUpdatedTimestamp)
          }
        })
    );

    return ret;
  }, [projects.data, projectResources.data]);

  const columns: TableColumnType<(typeof merged)[number]>[] = [
    {
      title: 'PacTS ID',
      render: (_, record) => record.pf?.id || String(record.project?.idProject) || '',
      key: 'id',
      ...useTableSearch({
        searchParamId: 'id',
        searchValueProvider: (record) => record.pf?.id || String(record.project?.idProject) || ''
      })
    },
    {
      title: 'Name',
      render: (_, record) => record.project?.name ?? record.pf?.name,
      key: 'name',
      ...useTableSearch({
        searchParamId: 'name',
        searchValueProvider: (record) => record.project?.name ?? record.pf?.name ?? ''
      })
    },
    {
      title: 'Status',
      render: (_, record) => {
        if (!record.pf) return <ImportProject project={record.project!} />;
        return record.pf?.infrastructureStatus;
      },
      key: 'status',
      ...useTableFilter({
        searchParamId: 'status',
        values: statuses.map((v: string) => ({ text: v, value: v })),
        onFilter(data, filterVal) {
          const typed = data as (typeof merged)[number] | undefined;
          if (!typed?.pf && filterVal === 'uninitialized') return true;
          return typed?.pf?.infrastructureStatus === filterVal;
        },
        key: 'statuses'
      })
    },
    {
      title: 'Type',
      render: (_, record) => record.pf?.type || '-',
      key: 'type',
      ...useTableFilter({
        searchParamId: 'type',
        values: projectTypes.map((v: string) => ({ text: v, value: v })),
        onFilter(data, filterVal) {
          const typed = data as (typeof merged)[number] | undefined;
          if (!typed?.pf && filterVal === 'undefined') return true;
          return typed?.pf?.type === filterVal;
        },
        key: 'type'
      })
    },
    {
      title: 'Solution Type',
      render: (_, record) => record.pf?.solutionType || '-',
      key: 'solutionType',
      ...useTableFilter({
        searchParamId: 'solutionType',
        values: solutionTypes.map((v: string) => ({ text: v, value: v })),
        onFilter(data, filterVal) {
          const typed = data as (typeof merged)[number] | undefined;
          if (!typed?.pf && filterVal === 'undefined') return true;
          return typed?.pf?.solutionType === filterVal;
        },
        key: 'solutionType'
      })
    },
    {
      title: 'Created',
      sorter: {
        compare: creationDateSorter
      },
      render: (_, record) => (record.pf?.creationTimestamp ? formatDateTime(record.pf.creationTimestamp || new Date(0)) : '-'),
      key: 'creationTimestamp'
    },
    {
      title: 'Updated',
      sorter: {
        compare: updationDateSorter
      },
      render: (_, record) => {
        return record.pf?.lastUpdatedTimestamp ? formatDateTime(record.pf?.lastUpdatedTimestamp || new Date(0)) : '-';
      },
      key: 'lastUpdatedTimestamp',
      defaultSortOrder: 'descend'
    }
  ];

  return (
    <FullWidthSpace direction="vertical">
      <StyledModal title={errorModal?.summary} open={errorModal != null} destroyOnClose onCancel={() => setErrorModal(null)} onOk={() => setErrorModal(null)}>
        <Typography.Paragraph>Error Code: {errorModal?.errorCode}</Typography.Paragraph>
        <Typography.Paragraph>Internal Error code: {errorModal?.technicalDetails?.internalErrorCode}</Typography.Paragraph>
        <Typography.Paragraph>Summary: {errorModal?.technicalDetails?.description}</Typography.Paragraph>
      </StyledModal>
      <Skeleton loading={isLoading} active>
        <Table
          scroll={{
            x: 'max-content'
          }}
          columns={columns}
          rowKey={(e) => e.project?.idProject ?? e.pf?.id ?? ''}
          dataSource={Object.values(merged)}
          expandable={{
            rowExpandable: (project) => (project as unknown as ProjectResource).infrastructureStatus !== 'DELETED',
            expandedRowRender: (project) => project.pf && <ProjectDetails onSetErrorModal={setErrorModal} project={project.pf} />
          }}
        />
      </Skeleton>
    </FullWidthSpace>
  );
};
