export class ReleaseNotesValidator {
  private static minLen = 10;

  private static maxLen = 16383;

  private static validate(unsafe?: string): Promise<void> {
    const sanitized = unsafe ?? '';
    const trimmed = sanitized.trim();
    if (trimmed.length < ReleaseNotesValidator.minLen) {
      return Promise.reject(new Error(`Must be at least ${this.minLen} characters long`));
    }
    if (trimmed.length > ReleaseNotesValidator.maxLen) {
      return Promise.reject(new Error(`Must be at most ${this.maxLen} characters long`));
    }
    return Promise.resolve();
  }

  public static rule() {
    return { required: true, validator: (_: any, value: string) => ReleaseNotesValidator.validate(value) };
  }
}
