import {
  Bundle,
  BundleConfiguration,
  BundleConfigurationCreate,
  BundleRelease,
  BundleReleaseCreate,
  BundleReleaseLite,
  Project,
  ProjectCreate,
  ProjectMember,
  ProjectMemberAccessRequest,
  ProjectMemberChange,
  ProjectPhase,
  ProjectPhaseCreate,
  ProjectRoleWithPermissions,
  ProjectRoleCreate,
  ProjectSolutionType,
  ProjectSolutionTypeCreate,
  ProjectType,
  ProjectTypeCreate,
  ProjectUpdate,
  SoftwareApp,
  SoftwareAppCategory,
  SoftwareAppCategoryCreate,
  SoftwareAppCreate,
  SoftwareAppUpdate,
  SoftwareAppUsage,
  SoftwareAppVersion,
  SoftwareAppVersionCreate,
  SoftwareAppVersionUpdate,
  Target,
  TargetCreate,
  Tool,
  ToolCategory,
  ToolCategoryCreate,
  ToolCreate,
  ToolUpdate,
  ToolUsage,
  ToolVersion,
  ToolVersionCreate,
  UploadToken,
  Download,
  ToolVersionVulnerability,
  SoftwareAppVersionVulnerability,
  SoftwareAppVersionPatch
} from '../domain/types';
import { ApiError } from '../../shared/apiError';

export class EngineeringBackendError extends ApiError {}

export interface EngineeringBackend {
  onError(handler: (error: EngineeringBackendError) => any): void;

  /* Projects */
  queryProjects(): Promise<Project[]>;
  queryProject(id: string): Promise<Project>;
  queryProjectPhases(): Promise<ProjectPhase[]>;
  queryProjectSolutionTypes(): Promise<ProjectSolutionType[]>;
  queryProjectTypes(): Promise<ProjectType[]>;

  createProject(create: ProjectCreate): Promise<Project>;
  createProjectPhase(create: ProjectPhaseCreate): Promise<ProjectPhase>;
  createProjectSolutionType(create: ProjectSolutionTypeCreate): Promise<ProjectSolutionType>;
  createProjectType(create: ProjectTypeCreate): Promise<ProjectType>;

  updateProject(update: ProjectUpdate): Promise<Project>;
  updateProjectPhase(update: ProjectPhase): Promise<ProjectPhase>;
  updateProjectSolutionType(update: ProjectSolutionType): Promise<ProjectSolutionType>;
  updateProjectType(update: Omit<ProjectType, 'isReferenceProject'>): Promise<ProjectType>;

  deleteProject(id: string): Promise<void>;
  deleteProjectPhase(id: string): Promise<void>;
  deleteProjectSolutionType(id: string): Promise<void>;
  deleteProjectType(id: string): Promise<void>;

  /* Bundles */
  queryBundle(projectId: string, bundleId: string): Promise<Bundle>;
  queryBundles(projectId: string): Promise<Bundle[]>;
  queryBundleConfigurations(projectId: string, bundleId: string): Promise<BundleConfiguration[]>;
  queryBundleConfiguration(projectId: string, bundleId: string, configurationId: string): Promise<BundleConfiguration>;
  queryBundleConfigurationVersion(
    projectId: string,
    bundleId: string,
    configurationId: string,
    versionId: string,
    shouldTriggerError?: boolean
  ): Promise<BundleRelease>;
  queryBundleConfigurationVersions(projectId: string, bundleId: string, configurationId: string): Promise<BundleReleaseLite[]>;
  
  createBundleConfiguration(projectId: string, bundleId: string, create: BundleConfigurationCreate): Promise<BundleConfiguration>;
  createBundleConfigurationVersion(projectId: string, bundleId: string, configurationId: string, create: BundleReleaseCreate): Promise<BundleRelease>;
  
  updateBundleConfiguration(projectId: string, bundleId: string, configurationId: BundleConfiguration): Promise<BundleConfiguration>;

  deleteBundle(projectId: number, bundleId: number): Promise<void>;
  deleteBundleConfiguration(projectId: string, bundleId: string, configurationId: string): Promise<void>;
  deleteBundleConfigurationVersion(projectId: string, bundleId: string, configurationId: string, versionid: string): Promise<void>;

  getBundleConfigurationReleaseReport(
    idProject: number,
    idBundle: number,
    idConfiguration: number,
    idRelease: number,
    reportTitle?: boolean,
    columns?: Array<'softwareName' | 'softwareDescription' | 'softwareVersion' | 'softwareReleaseNotes' | 'softwareChecksum'>,
    sign?: boolean,
    sort?: 'ASC' | 'DESC',
    format?: 'xlsx' | 'pdf'
  ): Promise<{ streamUri: string; signature?: string }>;

  /* Apps */
  queryCommonApp(id: string): Promise<SoftwareApp>;
  queryCommonApps(): Promise<SoftwareApp[]>;
  queryCommonAppUsage(id: string): Promise<SoftwareAppUsage[]>;
  queryCommonAppVersion(id: number, versionId: number): Promise<SoftwareAppVersion>;
  queryCommonAppVersions(id: string): Promise<SoftwareAppVersion[]>;
  queryCommonAppVersionVulnerabilities(appId: number, appVersionId: number): Promise<SoftwareAppVersionVulnerability[]>;
  queryProjectApp(projectId: string, appId: string): Promise<SoftwareApp>;
  queryProjectApps(projectId: string): Promise<SoftwareApp[]>;
  queryProjectAppVersion(projectId: number, appId: number, versionId: number): Promise<SoftwareAppVersion>;
  queryProjectAppVersions(projectId: string, appId: string): Promise<SoftwareAppVersion[]>;
  queryProjectAppVersionVulnerabilities(appId: number, appVersionId: number, projectId: number): Promise<SoftwareAppVersionVulnerability[]>;
  queryAppCategories(): Promise<SoftwareAppCategory[]>;
  queryAppTargets(): Promise<Target[]>;
  downloadProjectAppVersionTarget(projectId: number, appId: number, versionId: number, targetId: number): Promise<Download>;
  downloadCommonAppVersionTarget(appId: number, versionId: number, targetId: number): Promise<Download>;

  createAppCategory(create: SoftwareAppCategoryCreate): Promise<SoftwareAppCategory>;
  createAppTarget(create: TargetCreate): Promise<Target>;
  createCommonApp(create: SoftwareAppCreate): Promise<SoftwareApp>;
  createCommonAppVersion(appId: string, create: SoftwareAppVersionCreate): Promise<SoftwareAppVersion>;
  createProjectApp(projectId: string, create: SoftwareAppCreate): Promise<SoftwareApp>;
  createProjectSoftwareAppVersion(projectId: string, appId: string, create: SoftwareAppVersionCreate): Promise<SoftwareAppVersion>;

  updateAppCategory(update: SoftwareAppCategory): Promise<SoftwareAppCategory>;
  updateAppTarget(update: Target): Promise<Target>;
  updateCommonApp(update: SoftwareAppUpdate): Promise<SoftwareApp>;
  updateCommonAppVersion(appId: string, update: SoftwareAppVersionUpdate): Promise<SoftwareAppVersion>;
  patchCommonAppVersion(appId: string, update: SoftwareAppVersionPatch): Promise<SoftwareAppVersion>;
  updateProjectApp(projectId: string, update: SoftwareAppUpdate): Promise<SoftwareApp>;
  updateProjectAppVersion(projectId: string, appId: string, update: SoftwareAppVersionUpdate): Promise<SoftwareAppVersion>;
  patchProjectAppVersion(projectId: string, appId: string, update: SoftwareAppVersionPatch): Promise<SoftwareAppVersion>;

  deleteAppCategory(id: string): Promise<void>;
  deleteAppTarget(id: string): Promise<void>;
  deleteCommonSoftwareApp(id: string): Promise<void>;
  deleteCommonSoftwareAppVersion(appId: string, versionId: string): Promise<void>;
  deleteProjectSoftwareApp(projectId: string, appId: string): Promise<void>;
  deleteProjectSoftwareAppVersion(projectId: string, appId: string, versionId: string): Promise<void>;

  /* Tools */
  queryTool(id: string): Promise<Tool>;
  queryTools(): Promise<Tool[]>;
  queryToolUsage(id: string): Promise<ToolUsage[]>;
  queryToolVersion(id: number, versionId: number): Promise<ToolVersion>;
  queryToolVersions(id: string): Promise<ToolVersion[]>;
  queryToolVersionVulnerabilities(toolId: number, idToolVersion: number): Promise<ToolVersionVulnerability>;
  queryToolCategories(): Promise<ToolCategory[]>;
  downloadToolVersion(toolId: number, versionId: number): Promise<Download>;

  createTool(create: ToolCreate): Promise<Tool>;
  createToolVersion(toolId: string, create: ToolVersionCreate): Promise<ToolVersion>;
  createToolCategory(create: ToolCategoryCreate): Promise<ToolCategory>;

  updateTool(update: ToolUpdate): Promise<Tool>;
  updateToolVersion(toolId: string, update: ToolVersion): Promise<ToolVersion>;
  patchToolVersion(toolId: string, update: Partial<ToolVersion>): Promise<ToolVersion>;
  updateToolCategory(update: ToolCategory): Promise<ToolCategory>;

  deleteTool(toolId: string): Promise<void>;
  deleteToolVersion(toolId: string, versionId: string): Promise<void>;
  deleteToolCategory(id: string): Promise<void>;

  /* Members */
  queryProjectMembers(id: string): Promise<ProjectMember[]>;
  queryProjectRoles(): Promise<ProjectRoleWithPermissions[]>;

  assignProjectMember(projectId: string, member: ProjectMemberChange): Promise<ProjectMember>;
  unassignProjectMember(projectId: string, memberId: string): Promise<void>;

  createProjectRole(create: ProjectRoleCreate): Promise<ProjectRoleWithPermissions>;

  updateProjectMember(projectId: string, member: ProjectMemberChange): Promise<ProjectMember>;
  updateProjectRole(update: ProjectRoleWithPermissions): Promise<ProjectRoleWithPermissions>;

  deleteProjectRole(id: string): Promise<void>;

  getProjectAccessRequest(projectId: number): Promise<ProjectMemberAccessRequest | undefined>;
  addProjectAccessRequest(projectId: number): Promise<ProjectMemberAccessRequest>;
  deleteProjectAccessRequest(projectId: number, memberId: number): Promise<void>;
  getProjectAccessRequests(projectId: number): Promise<ProjectMemberAccessRequest[]>;

  /* Health */
  heartbeat(): Promise<void>;

  /* Upload Token */
  getTemporaryBlobUploadToken(contentType: string, fileName: string): Promise<UploadToken>;
}
