/* eslint-disable max-len */
import { PacTSIconLight } from './PacTSIconLight';
import { PacTSIconDark } from './PacTSIconDark';
import { useTheme, THEMES } from '../../../../contexts/theme';
import styled from 'styled-components';

export type PacTSIconProps = {
  style?: React.CSSProperties;
  disabled?: boolean;
};

const PacTSIconNormal: React.FunctionComponent<PacTSIconProps> = (props: PacTSIconProps) => {
  const [theme] = useTheme();

  const isLightTheme = theme === THEMES.LIGHT;

  return isLightTheme ? <PacTSIconDark {...props} /> : <PacTSIconLight {...props} />;
};

const PacTSIconDisabled = styled(PacTSIconNormal)`
  path {
    fill: ${(args) => args.theme.colorTextDisabled};
  }
`;

export const PacTSIcon: React.FunctionComponent<PacTSIconProps> = (props: PacTSIconProps) => {
  const [theme] = useTheme();

  if (props.disabled) {
    return <PacTSIconDisabled />;
  }

  const isLightTheme = theme === THEMES.LIGHT;

  return isLightTheme ? <PacTSIconDark {...props} /> : <PacTSIconLight {...props} />;
};
