import { usePermissions } from '../../session/hooks/usePermissions';
import type { SoftwareApp, SoftwareAppVersion } from '../../../api/engineering/domain/types';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useUnstuckSyncingProjectSoftwareAppVersion } from '../hooks/useUnstuckSyncingProjectSoftwareAppVersion';

export const UnstuckSyncingProjectSoftwareAppVersion = (props: { app: SoftwareApp; version: SoftwareAppVersion; projectId: string }) => {
  const { app, version, projectId } = props;

  const permissions = usePermissions({
    softwareAppId: app.idSoftwareApp.toString(),
    idToolVersion: version.idSoftwareAppVersion.toString(),
    projectId: projectId
  });
  const { mutate: unstuck, isLoading } = useUnstuckSyncingProjectSoftwareAppVersion();

  const disabled = version.state === 'consistent';

  // admin-only feature
  if (!permissions.all$unrestrictedAdministration) return null;

  // only show for syncing elements
  if (disabled) return null;

  return (
    <ConfirmationButton
      icon={<DoubleRightOutlined />}
      caption="Unstuck Syncing"
      okText="Unstuck"
      buttonType="expandable-menu"
      title="This will reset all the links and cannot be undone."
      danger
      loading={isLoading}
      onOk={() => unstuck([projectId, app.idSoftwareApp.toString(), version])}
    >
      Unstuck Syncing
    </ConfirmationButton>
  );
};
