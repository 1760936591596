import React from 'react';
import type { Project } from '../../../api/engineering/domain/types';
import { allSolutionTypeKey } from '../../projects/hooks/useSelectedProjectSolutionType';
import { useInAppNavigate } from '../../navigation/hooks';
import styled from 'styled-components';
import { Col, MenuProps, Row } from 'antd';
import { Link } from 'react-router-dom';
import { ProjectPin } from '../../projects/components/ProjectPin';
import { useBundleConfigurations, useBundles } from '../../../contexts/bundles';
import { useDeploymentEnvironments } from '../../deployments/hooks/useDeploymentEnvironments';
import { usePermissions } from '../../session';
import { useSources } from '../../reports';

type MenuItem = Required<MenuProps>['items'][number];

const FlexRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledMenuLink = styled(Link)`
  color: inherit;
  outline: 0;
  width: 100%;
`;

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  link: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
  onTitleClick?: () => void
): MenuItem => {
  return {
    key,
    icon,
    children,
    label: <StyledMenuLink to={link}>{label}</StyledMenuLink>,
    type,
    onTitleClick
  } as MenuItem;
};

export const useProjectsMenuItems = (
  selectableProjects: Project[],
  solutionType: string | null,
  projectType: string | undefined,
  search: string,
  active: string | null | undefined,
  setActive: (id: string) => void
) => {
  const navigate = useInAppNavigate();
  const environments = useDeploymentEnvironments(Number(active), !!active);
  const bundles = useBundles(String(active));
  const appsBundleId = bundles.data?.find((bundle) => bundle.name.includes('Applications'))?.idBundle.toString() || '';
  const toolsBundleId = bundles.data?.find((bundle) => bundle.name.includes('Tools'))?.idBundle.toString() || '';
  const appsConfigurations = useBundleConfigurations(String(active), appsBundleId).data;
  const toolsConfigurations = useBundleConfigurations(String(active), toolsBundleId).data;
  const permissions = usePermissions({ projectId: active || undefined });
  const sources = useSources(active ? Number(active) : -1);
  const hasReportPermissions = permissions.reportSvc$getReportSources && permissions.reportSvc$getReportRevisions;
  const hasAisForcePermissions = permissions.pAisForceSvc$calculateFlexibleConductor || permissions.pAisForceSvc$calculateRigidCylindricalConductor;
  const pdpTarget = sources.data?.find((s) => s.name === 'pam-pdp');
  const hasTarget = pdpTarget !== undefined;

  const solutionTypeFilteredProjects = selectableProjects
    .filter((p) => {
      if (solutionType === 'archived') {
        return p.isArchived;
      }
      if (p.isArchived) return false;
      if (!solutionType) return true;
      if (solutionType === allSolutionTypeKey) return true;
      return p.projectSolutionType.id.toString() === solutionType;
    })
    .filter((p) => {
      if (!search) return true;
      return p.name.toLowerCase().includes(search.toLowerCase());
    });

  const items: MenuItem[] = solutionTypeFilteredProjects.map((stp) => {
    const label = (
      <FlexRow wrap={false}>
        <Col flex="auto">
          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', marginRight: 4 }}>{stp.name}</div>
        </Col>
        <Col flex="24px">
          <ProjectPin projectId={stp.idProject.toString()} />
        </Col>
      </FlexRow>
    );

    const getConfigurations = (bundle: 'apps' | 'tools') => {
      return String(stp.idProject) === active
        ? (bundle === 'apps' ? appsConfigurations : toolsConfigurations)?.map((config) => ({
            key: `/projects/${stp.idProject}/${bundle}/configurations/${config.idBundleConfiguration}`,
            label: (
              <StyledMenuLink to={`/projects/${stp.idProject}/${bundle}/configurations/${config.idBundleConfiguration}?active=${active}&type=${projectType}`}>
                {config.name}
              </StyledMenuLink>
            ),
            onClick: () => {
              navigate(`/projects/${stp.idProject}/${bundle}/configurations/${config.idBundleConfiguration}?active=${active}&type=${projectType}`);
            }
          })) || []
        : [];
    };

    const envChildren =
      String(stp.idProject) === active
        ? environments.data?.map((env) => ({
            key: `/projects/${stp.idProject}/deployments/${env.id}`,
            label: <StyledMenuLink to={`/projects/${stp.idProject}/deployments/${env.id}?active=${active}&type=${projectType}`}>{env.name}</StyledMenuLink>,
            onClick: () => {
              navigate(`/projects/${stp.idProject}/deployments/${env.id}?active=${active}&type=${projectType}`);
            }
          })) || []
        : [];

    const children = [
      {
        key: `/projects/${stp.idProject}/apps`,
        label: <StyledMenuLink to={`/projects/${stp.idProject}/apps?active=${active}&type=${projectType}`}>Applications</StyledMenuLink>,
        onTitleClick: () => {
          navigate(`/projects/${stp.idProject}/apps?active=${active}&type=${projectType}`);
        },
        children: getConfigurations('apps')
      },
      {
        key: `/projects/${stp.idProject}/tools`,
        label: <StyledMenuLink to={`/projects/${stp.idProject}/tools?active=${active}&type=${projectType}`}>Tools</StyledMenuLink>,
        onTitleClick: () => {
          navigate(`/projects/${stp.idProject}/tools?active=${active}&type=${projectType}`);
        },
        children: getConfigurations('tools')
      },
      {
        key: `/projects/${stp.idProject}/deployments/environments`,
        label: <StyledMenuLink to={`/projects/${stp.idProject}/deployments/environments?active=${active}&type=${projectType}`}>Environments</StyledMenuLink>,
        onTitleClick: () => {
          navigate(`/projects/${stp.idProject}/deployments/environments?active=${active}&type=${projectType}`);
        },
        children: envChildren
      },
      hasAisForcePermissions
        ? {
            key: `/projects/${stp.idProject}/ais-force`,
            label: <StyledMenuLink to={`/projects/${stp.idProject}/ais-force?active=${active}&type=${projectType}`}>AIS Force</StyledMenuLink>,
            onClick: () => {
              navigate(`/projects/${stp.idProject}/ais-force?active=${active}&type=${projectType}`);
            }
          }
        : null,
      hasTarget && hasReportPermissions
        ? {
            key: `/projects/${stp.idProject}/reports/${pdpTarget?.id}/latest`,
            label: (
              <StyledMenuLink to={`/projects/${stp.idProject}/reports/${pdpTarget?.id}/latest?active=${active}&type=${projectType}`}>
                Plant Data Backbone
              </StyledMenuLink>
            ),
            onClick: () => {
              navigate(`/projects/${stp.idProject}/reports/${pdpTarget?.id}/latest?active=${active}&type=${projectType}`);
            }
          }
        : null,
      {
        key: `/projects/${stp.idProject}/members`,
        label: <StyledMenuLink to={`/projects/${stp.idProject}/members?active=${active}&type=${projectType}`}>Members</StyledMenuLink>,
        onClick: () => {
          navigate(`/projects/${stp.idProject}/members?active=${active}&type=${projectType}`);
        }
      }
    ];

    return getItem(label, stp.idProject, `/projects?active=${stp.idProject}`, undefined, children, undefined, () => {
      navigate(`/projects?active=${active}&type=${projectType}`);
      setActive(String(stp.idProject));
    });
  });

  return items;
};
