import { Alert, Row, Col, Space, Typography } from 'antd';
import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import { useBundles } from '../../bundles/hooks/useBundles';
import { useDeleteBundleConfiguration } from '../../bundles/hooks/useDeleteBundleConfiguration';
import { useDeleteBundleConfigurationVersion } from '../../bundles/hooks/useDeleteBundleConfigurationVersion';
import { useProject } from '../../projects/hooks/useProject';
import { usePermissions } from '../../session/hooks/usePermissions';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { EngineeringToolsCompareList } from './EngineeringToolsCompareList';
import { AddBundleConfiguration } from '../../bundles/components/AddBundleConfiguration';
import { AddBundleConfigurationVersion } from '../../bundles/components/AddBundleConfigurationVersion';
import { BundleConfigurationVersionDropdown } from '../../bundles/components/BundleConfigVersionDropdown';
import { ReportDownloadButton } from '../../bundles/components/BundleReportDownloadButton';
import { ComparisonViewSwitch } from '../../bundles/components/ComparisonViewSwitch';
import { RevertButton } from '../../bundles/components/RevertButton';
import { ShowBundleItemsOnlySwitch } from '../../bundles/components/ShowBundleItemsOnlySwitch';
import { ConfirmationButton } from '../../shared/components/ConfirmationButton';
import { useBundleConfigurationSelection } from '../hooks/useBundleConfigurationSelection';
import { useSearchParameter } from '../../navigation/hooks/useSearchParameter';
import { EngineeringToolSelection } from '../../../domain/engineeringToolsSelection';
import EngineeringToolsList from './EngineeringToolsList';
import { formatDateTime } from '../../shared/components/formatDate';
import { MarkdownPreview } from '../../shared/components/MarkdownPreview';
import { BundleConfigurationSelection } from '../../../domain/bundleConfigurationSelection';
import { Tool } from '../../../api/engineering/domain/types';
import { UpdateBundleConfiguration } from '../../bundles/components/EditBundleConfiguration';
import ProjectContentWrapper from '../../../contexts/projects/components/ProjectContentWrapper';
import { useParams } from 'react-router-dom';
import { useBundleConfigurationVersions, useBundleConfigurations } from '../../bundles';
import styled from 'styled-components';
import { Redirects } from '@pacts-plugin-api';

const { Text } = Typography;

const initialBundleSelection = {
  bundleId: '',
  projectId: '',
  bundleConfigurationId: '',
  bundleConfigurationVersionId: '',
  bundleConfigurationName: '',
  bundleConfigurationVersionName: ''
};

const Title = styled(Typography.Title)`
  margin: 0 !important;
  margin-bottom: 4px !important;
`;

const SpaceContainer = styled(Space)`
  float: right !important;
`;

const InnerSpace = styled(Space)`
  float: right !important;
  margin-bottom: 15px !important;
`;

const AlertContainer = styled(Alert)`
  margin-bottom: 20px !important;
  width: 100% !important;
  z-index: 5 !important;
`;

const hashSelection = (input: EngineeringToolSelection[]): string => {
  return (
    input
      // include id in hash since id changes might also result in a selection update
      .map((tool) => `tcommon${tool.engineeringTool.id}-${tool.version.idToolVersion}`)
      .sort()
      .join('+')
  );
};

export const EngineeringToolsBundleView = () => {
  const params = useParams();
  const [selectedBundleConfigPrimary, setSelectedBundleConfigPrimary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const [selectedBundleConfigSecondary, setSelectedBundleConfigSecondary] = useState<BundleConfigurationSelection>(initialBundleSelection);
  const projectId = useMemo(() => {
    return params?.projectId || '';
  }, [params?.projectId]);
  const permissions = usePermissions({ projectId: projectId.toString() });
  const emptyArray = useRef([]);
  const [selectedTools, setSelectedTools] = useState([] as EngineeringToolSelection[]);
  const [initialSelection, setInitialSelection] = useState([] as EngineeringToolSelection[]);
  const [comparisonViewSearchParam, setComparisonViewSearchParam] = useSearchParameter('mode');
  const [isDirty, setIsDirty] = useState(false);
  const [showSearchParameter] = useSearchParameter('show');
  const [projectType] = useSearchParameter('type');

  const comparisonView = comparisonViewSearchParam === 'compare';
  const setComparisonView = (enabled: boolean) => setComparisonViewSearchParam(enabled ? 'compare' : 'control');

  const project = useProject(projectId);
  const bundles = useBundles(projectId);

  const usedBundle = bundles.data?.find((bundle) => bundle.name.includes('Engineering'));
  const bundleId = usedBundle?.idBundle?.toString() || '';

  const deleteBundleConfigVersion = useDeleteBundleConfigurationVersion();
  const deleteBundleConfig = useDeleteBundleConfiguration();

  const bundleConfigurations = useBundleConfigurations(projectId, bundleId);
  const selectedConfig = (bundleConfigurations.data || []).find((config) => config.idBundleConfiguration === Number(params.configurationId));
  const bundleConfigurationsVersions = useBundleConfigurationVersions(
    projectId,
    bundleId,
    String(selectedConfig?.idBundleConfiguration || selectedBundleConfigPrimary?.bundleConfigurationId)
  );
  const isBundleConfigurationsVersionsEnabled =
    parseInt(bundleId, 10) > 0 &&
    parseInt(selectedBundleConfigPrimary?.bundleConfigurationId || '', 10) > 0 &&
    parseInt(projectId, 10) > 0 &&
    Boolean(selectedBundleConfigPrimary?.bundleConfigurationId || '');
  const configName = selectedConfig?.name;
  const isNoSelectedTools = selectedTools.length < 1;
  const showBundleItemsOnly = !showSearchParameter || showSearchParameter === 'bundle';

  // if a project is an rpl and has a bundle config, no further can be added
  const isRpl = !!project.data?.projectType.isReferenceProject;
  const addBundleConfigDisabledDueToRpl = isRpl && !!selectedBundleConfigPrimary?.bundleConfigurationId;

  const bundleReleaseA = useBundleConfigurationSelection(projectId, bundleId, selectedBundleConfigPrimary || initialBundleSelection);

  const bundleReleaseB = useBundleConfigurationSelection(projectId, bundleId, selectedBundleConfigSecondary || initialBundleSelection);
  const breadcrumbItems = [
    { title: project.data?.name, url: `/projects?active=${projectId}&type=${projectType}` },
    { title: 'Tools', url: `/projects/${projectId}/tools?active=${projectId}&type=${projectType}` },
    { title: configName, onClick: comparisonView ? () => setComparisonView(false) : undefined },
    ...(comparisonView
      ? [
          {
            title: 'Compare releases'
          }
        ]
      : [])
  ];

  const extractSelectionFromBundleRelease = useMemo(
    () => (data: Tool[] | undefined) => {
      if (!data) {
        return emptyArray.current;
      }
      return (
        data.map((et) => {
          return { engineeringTool: et, version: et.latestVersion };
        }) || emptyArray.current
      );
    },
    []
  );

  const extractedToolsLeft = useMemo(() => {
    if (bundleReleaseB.isLoading) return [];
    if (!bundleReleaseB.release) {
      return [];
    }
    return extractSelectionFromBundleRelease(bundleReleaseB.release.engineeringTools);
  }, [bundleReleaseB.isLoading, bundleReleaseB.release, extractSelectionFromBundleRelease]);

  const setSelectionToBundleContent = useCallback(
    (force?: boolean) => {
      // when bundle release data is updated
      // set empty if no data is available
      if (!bundleReleaseA.release) {
        setInitialSelection([]);
        setSelectedTools([]);
        return;
      }

      // if data is available calculate the content of the bundle
      // update the selection only if the bundle content changed
      // e.g., in case a different version is selected
      const releaseSelection = extractSelectionFromBundleRelease(bundleReleaseA.release.engineeringTools);
      const initialSelectionHash = hashSelection(initialSelection ?? []);
      const releaseHash = hashSelection(releaseSelection);

      // don't update the selection if the bundle content is equal
      if (initialSelectionHash === releaseHash && !force) return;

      // if bundle contents are not equal a different bundle has been selected
      // or the content of the current one has changed - update selection
      setInitialSelection(releaseSelection);
      setSelectedTools(releaseSelection);

      // only update callback bundleRelease data changes
    },
    // eslint-disable-next-line
    [bundleReleaseA.release]
  );

  const handleBundleConfigVersionChange = (val: BundleConfigurationSelection) => {
    // force-update the selection to the current bundle content
    // only if the selected bundle release id has changed
    if (val.bundleConfigurationVersionId !== selectedBundleConfigPrimary?.bundleConfigurationId) {
      setSelectionToBundleContent(true);
    }

    setSelectedBundleConfigPrimary(val);
  };

  // when updateSelection callback is updated
  // trigger selection update
  useEffect(() => {
    setSelectionToBundleContent();
  }, [setSelectionToBundleContent]);

  if (parseInt(projectId, 10) < 0) {
    return <Alert message="Invalid Project Id" type="error" />;
  }

  const configDropdownExtensions =
    (!comparisonView && [
      <ExpandableMenu id={`bundle-config-actions-${params?.projectId}-${bundleId}`}>
        {permissions.engineeringSvc$addProjectBundleConfiguration && selectedBundleConfigPrimary && (
          <AddBundleConfiguration
            type="default"
            projectId={projectId.toString()}
            disabled={addBundleConfigDisabledDueToRpl}
            bundleId={bundleId}
            onAddBundleConfiguration={(bc) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationId: bc.idBundleConfiguration?.toString() || '',
                bundleConfigurationVersionId: '-1'
              });
            }}
          />
        )}
        {permissions.engineeringSvc$updateProjectBundleConfiguration && selectedBundleConfigPrimary && (
          <UpdateBundleConfiguration
            projectId={projectId.toString()}
            bundleId={bundleId}
            configId={selectedBundleConfigPrimary.bundleConfigurationId}
            onEditBundleConfigurationName={(bc) => {
              setSelectedBundleConfigPrimary({
                ...selectedBundleConfigPrimary,
                bundleConfigurationId: bc.idBundleConfiguration?.toString() || '',
                bundleConfigurationVersionId: bc.latestBundleReleaseId?.toString() || '-1'
              });
            }}
          />
        )}
        {permissions.engineeringSvc$deleteProjectBundleConfiguration && selectedBundleConfigPrimary && (
          <ConfirmationButton
            caption="Delete Bundle Configuration"
            danger
            description="All deployment plans associated with this configuration will also be deleted."
            onOk={() => {
              deleteBundleConfig.mutate([projectId.toString(), bundleId, selectedBundleConfigPrimary.bundleConfigurationId]);
            }}
          />
        )}
      </ExpandableMenu>
    ]) ||
    [];

  const configVersionDropdownExtensions =
    (!comparisonView &&
      selectedBundleConfigPrimary && [
        <RevertButton key="revertBundleConfigurationVersion" disabled={!isDirty} onClick={() => setSelectedTools(initialSelection)} />,
        <ExpandableMenu id={`bundle-config-version-actions-${params?.projectId}-${bundleId}-${selectedBundleConfigPrimary.bundleConfigurationId}`}>
          {permissions.engineeringSvc$addProjectBundleConfigurationRelease && (
            <AddBundleConfigurationVersion
              key="addBundleConfigurationVersion"
              projectId={projectId.toString()}
              bundleId={bundleId}
              disabled={isNoSelectedTools}
              bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
              selectedProjectSoftwareAppVersionIds={emptyArray.current}
              selectedSoftwareAppVersionIds={emptyArray.current}
              selectedEngineeringToolVersionIds={selectedTools.map((st) => st.version.idToolVersion || -1).filter((sai) => sai > -1)}
              onAddBundleConfigurationVersion={(bcv) => {
                setSelectedBundleConfigPrimary({
                  ...selectedBundleConfigPrimary,
                  bundleConfigurationVersionId: bcv.idBundleRelease?.toString() || ''
                });
              }}
            />
          )}
          {permissions.engineeringSvc$deleteProjectBundleConfigurationRelease && (
            <ConfirmationButton
              caption="Delete Version"
              key="deleteBundleConfigurationVersion"
              danger
              onOk={() =>
                deleteBundleConfigVersion.mutate([
                  projectId.toString(),
                  bundleId,
                  selectedBundleConfigPrimary.bundleConfigurationId,
                  selectedBundleConfigPrimary.bundleConfigurationVersionId
                ])
              }
            />
          )}
        </ExpandableMenu>
      ]) ||
    [];

  const bundleActions = [
    <ExpandableMenu id={`bundle-config-version-extra-actions-${params?.projectId}-${bundleId}-${selectedBundleConfigPrimary?.bundleConfigurationId}`}>
      <ComparisonViewSwitch state={comparisonView} onChange={() => setComparisonView(!comparisonView)} />
      {!comparisonView &&
        permissions.engineeringSvc$getProjectBundleConfigurationReleaseReport &&
        bundleReleaseA.release &&
        project.data &&
        usedBundle &&
        selectedBundleConfigPrimary && (
          <ReportDownloadButton project={project.data} bundle={usedBundle} bundleConfig={selectedBundleConfigPrimary} isDirty={isDirty} />
        )}
    </ExpandableMenu>
  ];

  const aIsEmpty = !selectedBundleConfigPrimary?.bundleConfigurationVersionId;
  const bIsEmpty = !selectedBundleConfigSecondary?.bundleConfigurationVersionId;
  const aLoading = bundleReleaseA.isLoading && !aIsEmpty;
  const bLoading = comparisonView && bundleReleaseB.isLoading && !bIsEmpty;
  const loading = aLoading || bLoading;

  const hasBundles = !bundleConfigurationsVersions.isLoading && bundleConfigurationsVersions.data?.length && bundleConfigurationsVersions.data.length > 0;

  return (
    <ProjectContentWrapper isLoading={project.isLoading} breadcrumbItems={breadcrumbItems}>
      <Row justify="start">
        <Col flex="50%">
          {!!configName && <Title level={4}>{configName}</Title>}
          {!comparisonView && bundleReleaseA && (
            <>
              <Text type="secondary">Release Notes:</Text>
              <MarkdownPreview content={bundleReleaseA.release?.releaseNotes || ''} title="Release note" />
              <br />
              <Text type="secondary">
                {bundleReleaseA.release?.createdAt ? formatDateTime(new Date(bundleReleaseA.release?.createdAt)) : ''}
                {bundleReleaseA.release?.createdBy ? ` by ${bundleReleaseA.release?.createdBy}` : ''}
              </Text>
            </>
          )}
        </Col>
        <Col flex="auto">
          <SpaceContainer direction="vertical" size={0}>
            <InnerSpace>
              {permissions.engineeringSvc$addProjectBundleConfigurationRelease &&
                selectedBundleConfigPrimary &&
                !bundleConfigurationsVersions.isLoading &&
                (!bundleConfigurationsVersions.data || (bundleConfigurationsVersions.data?.length || 0) < 1) && (
                  <AddBundleConfigurationVersion
                    type="button"
                    key="addBundleConfigurationVersion"
                    disabled={isNoSelectedTools}
                    projectId={projectId.toString()}
                    bundleId={bundleId}
                    bundleConfigurationId={selectedBundleConfigPrimary.bundleConfigurationId}
                    selectedProjectSoftwareAppVersionIds={emptyArray.current}
                    selectedSoftwareAppVersionIds={emptyArray.current}
                    selectedEngineeringToolVersionIds={selectedTools.map((st) => st.version.idToolVersion || -1).filter((sai) => sai > -1)}
                    onAddBundleConfigurationVersion={(bcv) => {
                      setSelectedBundleConfigPrimary({
                        ...selectedBundleConfigPrimary,
                        bundleConfigurationVersionId: bcv.idBundleRelease?.toString() || ''
                      });
                    }}
                  />
                )}
            </InnerSpace>
            <SpaceContainer>
              {selectedBundleConfigPrimary && (
                <BundleConfigurationVersionDropdown
                  disableDropdown={comparisonView}
                  hideConfiguration={!comparisonView}
                  hideEmptyVesions={!comparisonView}
                  projectId={projectId.toString()}
                  bundleId={bundleId}
                  selected={selectedBundleConfigPrimary}
                  onSelect={handleBundleConfigVersionChange}
                  isDirty={isDirty}
                  configurationDropdownExtensions={configDropdownExtensions}
                  configurationVersionDropdownExtensions={configVersionDropdownExtensions}
                  id="r"
                />
              )}
              {comparisonView && selectedBundleConfigSecondary && (
                <BundleConfigurationVersionDropdown
                  disableLabel
                  projectId={projectId.toString()}
                  bundleId={bundleId}
                  selected={selectedBundleConfigSecondary}
                  onSelect={setSelectedBundleConfigSecondary}
                  id="l"
                />
              )}
            </SpaceContainer>
            <SpaceContainer>
              {!comparisonView &&
                selectedBundleConfigPrimary &&
                !bundleConfigurationsVersions.isLoading &&
                !bundleReleaseA.isLoading &&
                isBundleConfigurationsVersionsEnabled && (
                  <ShowBundleItemsOnlySwitch itemName="Tools" showAll={!hasBundles} extension={hasBundles ? [bundleActions] : undefined} />
                )}
              {comparisonView && selectedBundleConfigPrimary && selectedBundleConfigSecondary && bundleActions}
            </SpaceContainer>
          </SpaceContainer>
        </Col>
      </Row>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          {!bundleConfigurationsVersions.isLoading && !bundleConfigurationsVersions.data?.length && (
            <AlertContainer
              message={
                <Typography.Text>
                  <Typography.Text strong>Create your first release</Typography.Text>. Please select the software needed for the release before creating it.{' '}
                  <Typography.Link target="_blank" href={Redirects.WIKI_PACTS_CREATE_PROJECT_RELEASES}>
                    See How to create releases
                  </Typography.Link>
                  .
                </Typography.Text>
              }
              type="warning"
              showIcon
            />
          )}
          {!comparisonView && (
            <EngineeringToolsList
              selected={selectedTools}
              onDirty={setIsDirty}
              onSelect={setSelectedTools}
              showBundleItemsOnly={showBundleItemsOnly}
              initiallySelected={initialSelection}
              project={project.data}
              loading={loading}
            />
          )}
          {comparisonView && selectedBundleConfigSecondary && selectedBundleConfigPrimary && (
            <EngineeringToolsCompareList
              project={project.data}
              selectedTitleLeft={`${selectedBundleConfigSecondary.bundleConfigurationName}: ${selectedBundleConfigSecondary.bundleConfigurationVersionName}`}
              selectedTitleRight={`${selectedBundleConfigPrimary.bundleConfigurationName}: ${selectedBundleConfigPrimary.bundleConfigurationVersionName}`}
              selectedToolsRight={selectedTools}
              selectedToolsLeft={extractedToolsLeft}
              loading={loading}
            />
          )}
        </Col>
      </Row>
    </ProjectContentWrapper>
  );
};
