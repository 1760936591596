import { InfoCircleOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Project } from '../../../api/engineering/domain/types';
import { SoftwareAppSelection } from '../../../domain/softwareAppsSelections';
import { SubscriptionPopup } from '../../notifications/components/SubscriptionPopup';
import { ExpandableMenu } from '../../shared/components/ExpandableMenu';
import { ExpandableMenuItem } from '../../shared/components/ExpandableMenuItem';
import { ScopedSoftwareApp } from '../hooks/scopedSoftwareApp';
import { SoftwareAppsOpenInToolManagerButton } from './SoftwareAppsOpenInToolManagerButton';
import useSubmitMetric from '../../../contexts/metrics/hooks/useMetrics';
import { MetricClickIds } from '../../../contexts/metrics/constants';
import { DownloadContextMenuButton } from '../../shared/components/Download/components/DownloadContextMenuButton';
import { usePermissions } from '../../../contexts/session/hooks/usePermissions';

interface CommonSoftwareAppsActionMenuProps {
  swa: ScopedSoftwareApp;
  currentSelection: SoftwareAppSelection | undefined;
  project?: Project;
  onOpenDetailsDrawer: (app: ScopedSoftwareApp) => void;
  onOpenVersionDrawer: (app: ScopedSoftwareApp) => void;
}

export const CommonSoftwareAppsActionMenu: React.FC<CommonSoftwareAppsActionMenuProps> = (props) => {
  const metrics = useSubmitMetric();
  const permissions = usePermissions({
    projectId: props.project?.idProject.toString(),
    softwareAppId: props.currentSelection?.app.idSoftwareApp.toString(),
    idSoftwareAppVersion: props.currentSelection?.version.idSoftwareAppVersion.toString()
  });

  const canGetVersions =
    permissions.engineeringSvc$getCommonSoftwareAppVersions || permissions.engineeringSvc$getCommonSoftwareAppVersions$specific().length > 0;

  const handleOpenVersionDrawer = () => {
    props.onOpenVersionDrawer(props.swa);
  };

  const handleOpenDetailsDrawer = () => {
    props.onOpenDetailsDrawer(props.swa);
  };

  return (
    <>
      <Space>
        <SubscriptionPopup title={props.swa.name} topics={[{ key: `apps/${props.swa.idSoftwareApp}/**`, title: props.swa.name }]} type="text" />
        <ExpandableMenu textType id={`common-app-actions-${props.swa.idSoftwareApp}`}>
          {permissions.webui$showComponentDetails && (
            <ExpandableMenuItem
              icon={<InfoCircleOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentDetails
                  })
                  .withCommonAppContext(props.swa);
                handleOpenDetailsDrawer();
              }}
            >
              Details
            </ExpandableMenuItem>
          )}
          {canGetVersions && (
            <ExpandableMenuItem
              icon={<OrderedListOutlined />}
              onClick={() => {
                metrics
                  .submitClick({
                    operationId: MetricClickIds.componentVersions
                  })
                  .withCommonAppContext(props.swa);

                handleOpenVersionDrawer();
              }}
            >
              Versions
            </ExpandableMenuItem>
          )}
          {props.currentSelection && <DownloadContextMenuButton artifact={props.currentSelection.app} version={props.currentSelection.version} />}
          {props.currentSelection && <SoftwareAppsOpenInToolManagerButton selection={props.currentSelection} project={props.project} />}
        </ExpandableMenu>
      </Space>
    </>
  );
};
