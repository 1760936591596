import { Col, Layout, Row } from 'antd';
import { extractProjectId, ProjectsMenu } from './ProjectsMenu';
import React, { useEffect } from 'react';
import { useProjects } from '../hooks';
import { useLocalStorageState } from '../../../contexts/shared/hooks';
import { Comparator } from '../../../domain';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import styled from 'styled-components';
import { PageLoading } from '../../../contexts/shared/components/PageLoading';
import { useProjectTypeParam } from '../hooks/useProjectTypeParam';
import { useParams } from 'react-router-dom';
import { useAppHeaderHeight } from '../../../contexts/layout/hooks/useAppHeaderHeight';
import { ElementIds } from '../../../constants/elementIds';

interface ProjectsLayoutProps {
  children: JSX.Element;
}

const FullHeightCol = styled(Col)`
  height: 100%;
`;

const FullHeightContent = styled(Layout.Content)`
  height: 100%;
`;

const ProjectLayoutRoot = styled(Layout)<{ headerHeight: number }>`
  position: relative;
  margin-top: 0;
  height: calc(100vh - ${({ headerHeight }) => headerHeight}px);
  // force hide overflows of the project layout
  overflow: hidden;
`;

// the styled flex container fills up the whole height without header
const FullHeightSider = styled(Layout.Sider)<{ headerHeight: number }>`
  height: 100%;
  position: sticky;
  margin-right: 8px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ProjectsLayout: React.FC<ProjectsLayoutProps> = ({ children }) => {
  const projects = useProjects();
  const { projectId } = useParams();
  const [pinnedProjects] = useLocalStorageState<string[]>('pinned-projects');
  const [type] = useSearchParameter('type');

  const headerHeight = useAppHeaderHeight();

  const [active, setActive] = useSearchParameter('active');
  const typeFilteredProjects = (
    projects.data?.filter((p) => {
      return type ? p.projectType.idProjectType.toString() === type : true;
    }) || []
  )
    .sort((a, b) => Comparator.lexicographicalComparison(a.name, b.name))
    .sort((a, b) => {
      const aN = pinnedProjects?.includes(a.idProject.toString()) ? 1 : 0;
      const bN = pinnedProjects?.includes(b.idProject.toString()) ? 1 : 0;
      return bN - aN;
    });

  const loading = projects.isLoading;

  // It's safer to use the param since it is always defined
  const activeProject =
    typeFilteredProjects?.find((p) => p.idProject.toString() === (projectId ?? active)) ??
    (typeFilteredProjects.length > 0 ? typeFilteredProjects.find((proj) => !proj.isArchived) : undefined);

  // Set project type param based on active project
  useProjectTypeParam(activeProject);

  useEffect(() => {
    if (!active && activeProject && String(activeProject?.projectType?.idProjectType) === type) {
      // This fallback is made for the default one to be set only when there is no inferred active project.
      const activeProjectId = projectId || extractProjectId(location.pathname) || activeProject.idProject.toString();
      setActive(activeProjectId);
    }
  }, [active, activeProject, setActive, type, projectId]);

  if (loading) return <PageLoading />;

  return (
    <ProjectLayoutRoot headerHeight={headerHeight}>
      <Row wrap={false}>
        <Col>
          <FullHeightSider headerHeight={headerHeight} width={240} id={ElementIds.PROJECTS_MENU}>
            <ProjectsMenu active={active} setActive={setActive} selectableProjects={typeFilteredProjects} />
          </FullHeightSider>
        </Col>
        <FullHeightCol>
          <FullHeightContent>{children}</FullHeightContent>
        </FullHeightCol>
      </Row>
    </ProjectLayoutRoot>
  );
};

export default ProjectsLayout;
