import { Card } from 'antd';
import BreadcrumbsContainer from './BreadcrumbsContainer';
import { BreadcrumbItemType, BreadcrumbSeparatorType } from 'antd/es/breadcrumb/Breadcrumb';
import { useEffect, useState } from 'react';
import { useSearchParameter } from '../../../contexts/navigation/hooks';
import { useProjectTypes } from '../hooks';
import { useAppHeaderHeight } from '../../../contexts/layout/hooks/useAppHeaderHeight';
import { useProjectsMenuWidth } from '../hooks/useProjectsMenuWidth';
import styled from 'styled-components';

interface ProjectContentWrapperProps {
  isLoading?: boolean;
  title?: string;
  extra?: React.ReactNode;
  children: React.ReactNode;
  breadcrumbItems: Partial<BreadcrumbItemType & BreadcrumbSeparatorType>[];
}

const StyledCard = styled(Card)<{ projectMenuWidth: number; headerHeight: number; breadcrumbHeight: number }>`
  width: calc(100vw - ${({ projectMenuWidth }) => projectMenuWidth}px);
  overflow: auto;
  padding: 0;
  margin: 0;
  // subtract 1px to account for rounding errors
  height: calc(100vh - ${({ headerHeight }) => headerHeight}px - ${({ breadcrumbHeight }) => breadcrumbHeight}px - 1px);
`;

const ProjectContentWrapper: React.FC<ProjectContentWrapperProps> = ({ breadcrumbItems = [], title, isLoading, children, extra }) => {
  const [projectType] = useSearchParameter('type');
  const [actualProjectType, setActualProjectType] = useState(projectType);
  const projectTypes = useProjectTypes();
  const appHeaderHeight = useAppHeaderHeight();
  const projectsMenuWidth = useProjectsMenuWidth();
  const [breadcrumbsContainerRef, setBreadcrumbsContainerRef] = useState<HTMLDivElement | null>(null);

  const projectBreadcrumbByType = projectTypes.data?.reduce((acc: Record<string, any>, type) => {
    acc[String(type.idProjectType)] = {
      title: type.name
    };
    return acc;
  }, {});

  const parentBreadcrumb = projectBreadcrumbByType?.[String(actualProjectType) || ''];
  const projectBreadcrumbItems = !!parentBreadcrumb ? [parentBreadcrumb, ...breadcrumbItems] : breadcrumbItems;

  useEffect(() => {
    if (!projectType) {
      return;
    }

    setActualProjectType(projectType);
  }, [projectType]);

  return (
    <>
      <div ref={(ref) => setBreadcrumbsContainerRef(ref)}>{breadcrumbItems.length > 0 && <BreadcrumbsContainer items={projectBreadcrumbItems} />}</div>
      <StyledCard
        breadcrumbHeight={breadcrumbsContainerRef?.clientHeight || 0}
        headerHeight={appHeaderHeight}
        projectMenuWidth={projectsMenuWidth}
        title={title}
        bordered={false}
        extra={extra}
        loading={isLoading}
      >
        {children}
      </StyledCard>
    </>
  );
};
export default ProjectContentWrapper;
